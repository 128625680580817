<template>
    <div id="Purpose">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'Purpose',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [
                this.$ipOrganization,
                'purpose',
                {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_purpose_type',
                            operator: '=',
                            value: 1
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 10
                        }
                    ]
                }
            ]
        },
    },

    data: () => ({
        tableRows: 10000,

        options: [
            {
                column: 'id_purpose_version',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Versão dos Propósitos',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-version' ]
            },
            {
                column: 'id_approval_flow',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Fluxo de aprovação',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipApprovalFlow, 'approval-flow' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 10
                    }
                ]
            },
            {
                column: 'id_purpose_main',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Propósito principal',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_purpose_type',
                        operator: '=',
                        value: 1
                    }
                ]
            },
            {
                column: 'id_purpose_ratio_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo do rateio do propósito',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-ratio-type' ],
                // conditions: [
                //     {
                //         AndOr: 'AND',
                //         column: 'id_module',
                //         operator: '=',
                //         value: 5
                //     }
                // ]
            },
            {
                column: 'id_planning_function',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tela de planejamento',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'planning-function' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 10
                    }
                ]
            },
            {
                column: 'id_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo de Usuários',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSecurity, 'group' ]
            }
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>